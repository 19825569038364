<template>
    <div class="">
        <checkout-header :back-button-action="isDesktop ? false : backBtnAction" class="-mb-4">
            <template v-if="!isDesktop" v-slot:back> {{ $t("checkout.back_init") }} </template>
            <template v-slot:header>
                <template>
                    <span data-cy="choose-wizard_control-text">
                        2 {{ $t("checkout.of") }} 2 &mdash;
                    </span>
                </template>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{ $t("migration.change_expiration") }}</strong
                >
            </template>
        </checkout-header>
        <div class="grid place-items-center" >
            <div div data-cy="migration-date-simulation">
                <span class="font-bold">{{
                    $t("migration.date_variation", {
                        dateInit: this.dateInit,
                        dateFinal: this.dateFinal,
                    })
                }}</span>
                <span v-if="isCreditCard()" class="font-bold">{{
                    $t("migration.charge_variation")
                }}</span>
            </div>
            <div>
                <form
                    class="w-full mt-8"
                    method="post"
                    @submit.prevent="migration"
                >
                    <input type="hidden" name="remember" value="true" />
                    <div class="rounded-md">
                        <p>{{ $t("migration.register_password") }}</p>
                        <div class="mt-3">
                            <input
                                id="user_password"
                                v-model="password"
                                minlength="3"
                                maxlength="12"
                                class="nex-input"
                                required
                                type="password"
                                :placeholder="$t('password')"
                                data-cy="migration-password-input"
                                @click="eventGtm('custom.setPassword')"
                            />
                        </div>
                    </div>

                    <div class="mt-6">
                        <submit-button-default
                            :text="$t('confirm')"
                            data-cy="migration-submit-btn"
                            :loading="loading"
                            @click.native="eventGtm('custom.confirmMigration')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CheckoutHeader from "@/components/Checkout/Header.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mapGetters, mapActions } from "vuex";
import {
    HOME_VIEW,
    MIGRATION_CONFIRMATION_VIEW,
    MIGRATION_END_VIEW,
    MIGRATION_PLAN_VIEW,
} from "@/router/constants";
import {
    EXEC_MIGRATION,
    MIGRATION_MODULE,
    SIMULATE_MIGRATION,
} from "@/store/constants/migration";
import { GET_CHOSEN_PLAN, GET_PLAN, GET_PLANS } from "@/store/constants/checkout";
import { CHANGE_LOADING } from "@/store/constants/login";
import { STORE, STORE_MODULE } from "@/store/constants/store";
import * as config from "../constants";

export default {
    name: MIGRATION_CONFIRMATION_VIEW,
    components: {
        CheckoutHeader,
        SubmitButtonDefault,
    },
    data() {
        return {
            password: null,
            loading: false,
            dateInit: null,
            dateFinal: null,
            planTrial: null,
            plansName: config.PlansdefaultNames,
        };
    },

    computed: {
        ...mapGetters({
            getPlan: `${MIGRATION_MODULE}/${GET_PLAN}`,
            getChosenPlan: `${MIGRATION_MODULE}/${GET_CHOSEN_PLAN}`,
            getStore: `${STORE_MODULE}/${STORE}`,
            getPlans: `${MIGRATION_MODULE}/${GET_PLANS}`,
            isDesktop: `isDesktop`,
            eventGtm: 'eventGtm'
        }),
    },

    async created() {
        if (!this.getChosenPlan) {
            return this.$router.push({
                name: HOME_VIEW,
            });
        }
        if(!this.getPlan && this.getStore.subscription.is_trial){
            this.planTrial = this.getPlans.filter(item => item.name == this.plansName[this.getStore.subscription?.license_type] && item.recurrencePeriod == 1 )[0]
        }
        this.changeLoading(true);
        try {
            await this.simulateMigration();
        } catch (error) {
            this.errorHandling(error);
        } finally {
            this.changeLoading(false);
        }
    },

    methods: {
        ...mapActions({
            changeLoading: CHANGE_LOADING,
            simulationAction: `${MIGRATION_MODULE}/${SIMULATE_MIGRATION}`,
            execMigrationAction: `${MIGRATION_MODULE}/${EXEC_MIGRATION}`,
        }),
        backBtnAction() {
            return this.$router.push({
                name: MIGRATION_PLAN_VIEW,
            });
        },
        async simulateMigration() {
            const simulatedDate = await this.simulationAction({
                toPlan: this.getChosenPlan.priceId,
                fromPlan: this.getPlan?.priceId || this.planTrial.priceId,
                recurrence: this.getChosenPlan.recurrencePeriod,
            });
            this.dateInit = simulatedDate.oldLicenseExpiration;
            this.dateFinal = simulatedDate.newLicenseExpiration;
        },

        async execMigration() {
            const migration = await this.execMigrationAction({
                toPlan: this.getChosenPlan.priceId,
                fromPlan: this.getPlan?.priceId || this.planTrial.priceId,
                recurrence: this.getChosenPlan.recurrencePeriod,
                password: this.password,
                paymentMethod: "credit_card",
            });

            return migration;
        },

        async migration() {
            this.loading = true;
            try {
                await this.execMigration();
            } catch (error) {
                this.errorHandling(error);
            } finally {
                this.loading = false;
            }
            this.$router.push({ name: MIGRATION_END_VIEW });
        },

        errorHandling(error) {
            this.$fire({
                text: 'Senha inválida',
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            });
            throw new Error(JSON.stringify(error));
        },
        isCreditCard() {
            return this.getStore.subscription.payment_type_code == "credit_card";
        }
    },
};
</script>

<style lang="scss" scoped></style>
