var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("checkout-header", {
        staticClass: "-mb-4",
        attrs: {
          "back-button-action": _vm.isDesktop ? false : _vm.backBtnAction,
        },
        scopedSlots: _vm._u(
          [
            !_vm.isDesktop
              ? {
                  key: "back",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("checkout.back_init")) + " "),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "header",
              fn: function () {
                return [
                  [
                    _c(
                      "span",
                      { attrs: { "data-cy": "choose-wizard_control-text" } },
                      [_vm._v(" 2 " + _vm._s(_vm.$t("checkout.of")) + " 2 — ")]
                    ),
                  ],
                  _c(
                    "strong",
                    {
                      attrs: { "data-cy": "choose-wizard_control_choose-text" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("migration.change_expiration"))
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("div", { staticClass: "grid place-items-center" }, [
        _c(
          "div",
          { attrs: { div: "", "data-cy": "migration-date-simulation" } },
          [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("migration.date_variation", {
                    dateInit: this.dateInit,
                    dateFinal: this.dateFinal,
                  })
                )
              ),
            ]),
            _vm.isCreditCard()
              ? _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("migration.charge_variation"))),
                ])
              : _vm._e(),
          ]
        ),
        _c("div", [
          _c(
            "form",
            {
              staticClass: "w-full mt-8",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.migration.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "remember", value: "true" },
              }),
              _c("div", { staticClass: "rounded-md" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("migration.register_password"))),
                ]),
                _c("div", { staticClass: "mt-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "nex-input",
                    attrs: {
                      id: "user_password",
                      minlength: "3",
                      maxlength: "12",
                      required: "",
                      type: "password",
                      placeholder: _vm.$t("password"),
                      "data-cy": "migration-password-input",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      click: function ($event) {
                        return _vm.eventGtm("custom.setPassword")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c("submit-button-default", {
                    attrs: {
                      text: _vm.$t("confirm"),
                      "data-cy": "migration-submit-btn",
                      loading: _vm.loading,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.eventGtm("custom.confirmMigration")
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }